@import '~@jutro/theme/assets/sass/helpers';

.darkSearchContainer {
  width: 100%;
  max-width: var(--GW-HEADER-SEARCH-MAX-WIDTH);

  .selectIcon {
    color: var(--GW-HEADER-SEARCH-SELECT-ICON-COLOR);
  }

  .cancel {
    color: var(--GW-HEADER-SEARCH-CANCEL-ICON-COLOR);
  }

  .placeholder {
    color: var(--GW-HEADER-SEARCH-COLOR);
  }

  .control {
    background-color: var(--GW-HEADER-SEARCH-BACKGROUND-COLOR);
    color: var(--GW-HEADER-SEARCH-COLOR);
    border-color: transparent;
    min-height: var(--GW-HEADER-SEARCH-HEIGHT);
    @include typography-input;

    div {
      color: inherit;
    }

    &:hover {
      background-color: var(--GW-HEADER-SEARCH-BACKGROUND-COLOR-HOVER);
    }
  }

  .controlFocused {
    @include focus-shadow-style(var(--GW-FOCUS-COLOR-DARK));

    background-color: var(--GW-HEADER-SEARCH-BACKGROUND-COLOR);
    color: var(--GW-HEADER-SEARCH-COLOR);
    border-color: var(--GW-HEADER-SEARCH-BORDER-COLOR-FOCUS);
  }

  .value {
    .title {
      color: var(--GW-HEADER-SEARCH-COLOR);
    }
  }
}

.searchContainer {
  width: 100%;
}
