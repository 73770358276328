@import '~@jutro/theme/assets/sass/helpers';

.header {
  grid-area: header;
  --GW-CONSUMER-HEADER-BACKGROUND-COLOR: var(--WHITE-COLOR);
  border: initial;
}

.globalization {
  --GW-GLOBALIZATION-WIDTH: 15rem;

  width: var(--GW-GLOBALIZATION-WIDTH);
}

.referrerButton i {
  color: var(--GW-LINK-COLOR) !important;
  font-size: 1.6rem;
}

.burgerMenuButton svg {
  color: var(--GW-LINK-COLOR) !important;
}

.logoWrapper {
  display: flex;
  flex: 1 0 0;
  min-width: 0;

  img {
    object-fit: contain;
  }

  @include gw-breakpoint-up(desktop) {
    padding-right: var(--GW-SPACING-6);
  }

  @include gw-breakpoint-down(tablet) {
    justify-content: center;
  }
}

.centerWrapper {
  display: flex;
  flex: 1 1 var(--GW-HEADER-SEARCH-MAX-WIDTH);
  justify-content: center;
  min-width: var(--GW-HEADER-SEARCH-MAX-WIDTH);
  margin-right: auto;
}

.headerActions {
  display: flex;
  justify-content: flex-end;
  padding-left: var(--GW-SPACING-6);
  z-index: var(--GW-Z-INDEX-1);

  @include gw-breakpoint-up(desktop) {
    flex: 0 0 auto;
  }

  :global(.jut__Grid__grid) {
    display: block;
  }
}

.narrowGlobalizationChooser {
  grid-template-columns: repeat(1, 1fr) !important;
}

.fullWidth,
.fullWidth>div {
  width: 100%;
  flex-direction: column;
}

.fullWidth input[type="text"] {
  --gw-right-padding: calc(3 * var(--GW-SPACING-4));
  padding-right: var(--gw-right-padding);
}


.inputSearchContainer {
  position: relative;
  width: var(--HEADER-SEARCHBAR-WIDTH);
  input {
    font-size: var(--HEADER-SEARCHBAR-FONT-SIZE);
  }
}

.searchIcon {
  --gw-top-position: 7px;
  --gw-right-position: 7px;
  position: absolute;
  right: var(--gw-right-position);
  top: var(--gw-top-position);
}
