/* initialize the helpers here*/
/* ....*/
/* end initialization*/

@import '../generated/styles/style-tools.scss';

/* customize the styles here*/
/* ...*/
/* end customziation*/

@import '../generated/styles/styles.scss';
@import '~gw-capability-gateway-react/gateway-styles';

:root {
  --gw-producer-engage-container-max-width: 90em;
  --gw-producer-engage-container-width: 90%;
  --gw-producer-table-icon-spa: 4px;
  --gw-main-container-padding: 180px;
  --gw-sub-title-padding-bottom: var(--GW-SPACING-4);
  --gw-tablet-sidebar-width: 20rem;
}

html,
body {
  height: 100%;
}

table[class*='tableElement'] {
  a {
    text-decoration: none;
    color: var(--GW-LINK-COLOR);
  }

  i {
    margin-right: var(--gw-producer-table-icon-spa);
  }
}

.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

/* .tilesContainer {*/
/*   padding-bottom: var(--GW-LAYOUT-4);*/
/* }*/

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;

  > * {
    max-height: var(--gw-modal-max-height);
  }
}

.appHeader {
  [class*='applicationLogoTitleLink'] {
    border: none;

    &:active,
    &:hover {
      background: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  [class*='headerActions'] {
    min-width: 64px;
  }
}

/* not possible to override global Loader*/
/* component registration will override only metadata components, not JSX*/
.jut__Loader__loaderAlertWrapper {
  position: relative;
  left: -9999px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--GW-BRAND-COLOR-1);
  color: var(--GW-BRAND-COLOR-1);
  box-shadow: 9999px 0 0 -15px var(--GW-BRAND-COLOR-1);
  animation: dotPulse 1.5s infinite ease-in-out;
  animation-delay: .25s;
}

.jut__Loader__loaderAlertWrapper::before, .jut__Loader__loaderAlertWrapper::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--GW-BRAND-COLOR-1);
  color: var(--GW-BRAND-COLOR-1);
}

.jut__Loader__loaderAlertWrapper::before {
  box-shadow: 9984px 0 0 -15px var(--GW-BRAND-COLOR-1);
  animation: dotPulseBefore 1.5s infinite ease-in-out;
  animation-delay: 0s;
  right: 50px;
}

.jut__Loader__loaderAlertWrapper::after {
  box-shadow: 10030px 0 0 -15px var(--GW-BRAND-COLOR-1);
  animation: dotPulseAfter 1.5s infinite ease-in-out;
  animation-delay: .5s;
  left: 50px;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -15px var(--GW-BRAND-COLOR-1);
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--GW-BRAND-COLOR-1);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -15px var(--GW-BRAND-COLOR-1);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -15px var(--GW-BRAND-COLOR-1);
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--GW-BRAND-COLOR-1);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -15px var(--GW-BRAND-COLOR-1);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10030px 0 0 -15px var(--GW-BRAND-COLOR-1);
  }
  30% {
    box-shadow: 10030px 0 0 2px var(--GW-BRAND-COLOR-1);
  }
  60%,
  100% {
    box-shadow: 10030px 0 0 -15px var(--GW-BRAND-COLOR-1);
  }
}

/* disabled GW loader*/
.jut__BrandedLoader__brandedLoader svg {
  display: none;
}

@include gw-breakpoint-down(tablet) {
  .jut__AppFloorPlan__mainContent.jut__AppFloorPlan__default {
    padding: 0 var(--GW-LAYOUT-4)
  }
}

/* enlarge wizard container to use more space on bigger screens*/
div.app__DigitalWizardPageWithTitleTemplate__wizardContainer {
  --wizard-template-max-width: 1280px;
}

/* align tiles on Landing/Accounts/Contracts/Activities/Summary page*/
.tilesContainer, #landingPageTileContainer > div {
  gap: var(--GW-LAYOUT-4);
  padding-bottom: var(--GW-LAYOUT-4) !important;
  padding-top: var(--GW-LAYOUT-4) !important;
  .app__TileComponent__gwTiles, .app__TileComponent__linkStyles {
      margin: 0 !important;
      padding: 0 !important;
  };
}

/* PV-22145: remove default belgium placeholder from phone number inputs*/
.jut__IntlPhoneNumberField__phoneNumber[placeholder="470 12 34 56"]::placeholder {
  color: transparent !important;
}

/* AsyncButtonLink does not have type attribute. With this class Button.type=outlined is mimicked*/
.asyncButtonLinkTypeOutlined {
  &.jut__Button__button.jut__Button__filled {
    background-color: var(--GW-BUTTON-OUTLINED-BACKGROUND-COLOR);
    border: var(--GW-BUTTON-OUTLINED-BORDER-WIDTH) solid var(--GW-BUTTON-OUTLINED-BORDER-COLOR);
    color: var(--GW-BUTTON-OUTLINED-COLOR);
    &:hover:not(.jut__Button__disabled) {
      color: var(--GW-BUTTON-OUTLINED-COLOR-HOVER);
      background-color: var(--GW-BRAND-COLOR-1);
    }
    &:active:not(.jut__Button__disabled) {
      color: var(--GW-BUTTON-OUTLINED-COLOR-HOVER);
      background-color: var(--GW-BUTTON-OUTLINED-BACKGROUND-COLOR-ACTIVE);
    }
    &.buttonPrimary {
      background-color: var(--GW-BUTTON-OUTLINED-BACKGROUND-COLOR);
      border: var(--GW-BUTTON-OUTLINED-BORDER-WIDTH) solid var(--GW-BRAND-COLOR-2);
      color: var(--GW-BRAND-COLOR-2);
      &:hover:not(.jut__Button__disabled) {
        color: var(--GW-BUTTON-OUTLINED-COLOR-HOVER);
        background-color: var(--GW-BRAND-COLOR-2);
      }
      &:active:not(.jut__Button__disabled) {
        color: var(--GW-BUTTON-OUTLINED-COLOR-HOVER);
        background-color: var(--GW-BRAND-COLOR-2);
      }
    }
  }
}