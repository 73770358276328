@import '../variables/breakpoints-vars';

@function breakpoint-min($name) {
  $n: if($name == 'desktop', -1, previous($name));
  $key: if($n != 0, nth(map-keys($breakpoints), $n), null);
  $min: if($key, map-get($breakpoints, $key) + 1px, 0);

  @return if($min != 0, $min, null);
}

@function breakpoint-max($name) {
  $breakpoint-names: map-keys($breakpoints);
  $n: index($breakpoint-names, $name);
  $key: if(
    $n and $n <= length($breakpoint-names),
    nth($breakpoint-names, $n),
    null
  );

  @return if($key, map-get($breakpoints, $key), null);
}

@function previous($name) {
  $n: index(map-keys($breakpoints), $name);

  @return if($n > 0, $n - 1, null);
}

// Makes the @content apply to the given breakpoint and wider.
@mixin gw-breakpoint-up($name) {
  $min: breakpoint-min($name);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Makes the @content apply to the given breakpoint and narrower.
@mixin gw-breakpoint-down($name) {
  $max: breakpoint-max($name);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media between the breakpoint"s minimum and maximum widths.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin gw-breakpoint-only($name) {
  $min: breakpoint-min($name);
  $max: breakpoint-max($name);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include gw-breakpoint-up($name) {
      @content;
    }
  } @else if $min == null {
    @include gw-breakpoint-down($name) {
      @content;
    }
  }
}

@mixin gw-retina-image() {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}
