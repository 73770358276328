@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('#{$nunito-sans-root}/WOFF2/NunitoSans-Regular.woff2')
      format('woff2'),
    url('#{$nunito-sans-root}/WOFF/NunitoSans-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('#{$nunito-sans-root}/WOFF2/NunitoSans-SemiBold.woff2')
      format('woff2'),
    url('#{$nunito-sans-root}/WOFF/NunitoSans-SemiBold.woff') format('woff');
  font-display: swap;
}
